import React from "react";

const Footer = () => {
  return (
    <>
      {" "}
      <footer className="footer footer-static footer-light mt-auto">
        <p className="clearfix mb-0">
          <span className="float-md-start d-block d-md-inline-block mt-25">
            COPYRIGHT © 2024
            <a className="ms-25" href="https://www.cqlsys.com/">
              CQLsys.Tecnology
            </a>
          </span>
        </p>
      </footer>
      <button className="btn btn-primary btn-icon scroll-top" type="button">
        <i data-feather="arrow-up" />
      </button>
    </>
  );
};

export default Footer;
